'format es6';
'use strict';

	var name = 'aseq';

	import $ from 'jquery';
	import Promise from 'Promise';

	try{require('source-map-'+'support').install();}catch(e){}

	var ns = window[name] = (window[name] || {});


	ns.window = $(window);

	ns.getBody = (function(){
		var body;
		return function(){
			return body = body || $('body');
		};
	})();

	window.requestAnimFrame = (function(){
		return window.requestAnimationFrame ||
			window.webkitRequestAnimationFrame ||
			window.mozRequestAnimationFrame ||
			function( callback ){
				window.setTimeout(callback, 1000 / 60);
			};
	})();

	if(navigator.userAgent.match(/Edge\/12\./) || navigator.userAgent.match(/Trident\/7\./)) {
		$('body').on("mousewheel", function () {
			event.preventDefault();
			var wd = event.wheelDelta;
			var csp = window.pageYOffset;
			window.scrollTo(0, csp - wd);
		});
	}

	/**
	 * generates unique id
	 */
	ns.getUniqueID = function(){
		var id = 0;
		return function(){
			return 'uid' + id++;
		}
	}();

	ns.detectIE = function() {
        var ua = window.navigator.userAgent;

        var msie = ua.indexOf('MSIE ');
        if (msie > -1) {
            // IE 10 or older => return version number
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }

        var trident = ua.indexOf('Trident/');
        if (trident > -1) {
            // IE 11 => return version number
            var rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }

        var edge = ua.indexOf('Edge/');
        if (edge > -1) {
           // IE 12 => return version number
           return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }

        // other browser
        return false;
    }

	ns.detectFF = function(){
        var ua = window.navigator.userAgent;

		var firefox = ua.indexOf('Firefox/');
        if (firefox > -1) {
           return true;
        }

		return false;
	}

	ns.docReady = (function(){
		return new Promise(function(resolve, reject) {
			$(document).ready(function(){
				resolve();
			});
		});
	})();

	ns.QueryString = function () {
      // This function is anonymous, is executed immediately and
      // the return value is assigned to QueryString!
      var query_string = {};
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i=0;i<vars.length;i++) {
        var pair = vars[i].split("=");
            // If first entry with this name
        if (typeof query_string[pair[0]] === "undefined") {
          query_string[pair[0]] = decodeURIComponent(pair[1]);
            // If second entry with this name
        } else if (typeof query_string[pair[0]] === "string") {
          var arr = [ query_string[pair[0]],decodeURIComponent(pair[1]) ];
          query_string[pair[0]] = arr;
            // If third or later entry with this name
        } else {
          query_string[pair[0]].push(decodeURIComponent(pair[1]));
        }
      }
        return query_string;
    }();

	ns.docReady.then(function(){
		//console.log("ready");
	});

	export default ns;
