"format es6";
"use strict";


import ns from 'ns';
import $ from 'jquery';
import gsap from 'greensock';

import MarkerCluster from '../vendor/markerclusterer.js';
import WhatsNearby from '../vendor/WhatsNearby.js';
import MapStyle from './MapStyle.json';

ns.docReady.then(function(){
	const protocol = window.location.href.split("/")[0];
	const POINTS_URL = 'proxy.php?url='+protocol+'//ihaveaplan.ca/json/netlist.asp';
	const POINT_UNIQUE_URL = 'proxy.php?url='+protocol+'//ihaveaplan.ca/json/netmember.asp';
	const NETWORKS_ID = {
		"1": "dental",
		"2": "vision",
		"3": "chiropractic",
		"6": "massage therapy",
		"7": "physiotherapy",
		"10": "pharmacy"
	};
	const NETWORKS = {
		"fr": {
			"chiropractic":"Chiropraticien",
			"dental":"Dentiste",
			"health":"Santé",
			"massage therapy":"Massothérapeute",
			"pharmacy":"Pharmacien",
			"physiotherapy":"Physiothérapeute",
			"psychology":"Psychologue",
			"naturopathy":"Naturopathe",
			"travel":"Voyage",
			"vision":"Opticien"
		},
		"en": {
			"chiropractic":"Chiropractic",
			"dental":"Dental",
			"health":"Health",
			"massage therapy":"Massage Therapy",
			"pharmacy":"Pharmacy",
			"physiotherapy":"Physiotherapy",
			"psychology":"Psychologist",
			"naturopathy":"Naturopath",
			"travel":"Travel",
			"vision":"Vision"
		}
	};
	const LANGS = { fr: 2, en: 1 };
	let isGeolocated = false;
	const DEFAULT_LOCATION = { lat: 52.3148109, lng: -97.8471394 };

	const MAP_STYLE = MapStyle;

	let map;

	//parsers pour transformer un array (recu en ajax) en un objet selon l'ordre des clés
	const Parser = (function() {

		var KEYS = {
			point: ['id', 'lat', 'lng', 'type', 'secure'],
			details: [
				'providerID',
				'phone',
				'email',
				'website',
				'whatever', //semble avoir un champ de trop dans le fichier exemple fourni par emmanuel
				'isvip',
				'title',
				'firstname',
				'lastname',
				'clinic',
				'addr1',
				'address2',
				'city',
				'prov',
				'postalcode',
				'benefit'
			]
		};

		var parse = function(ar) {
			return this.reduce(function(obj, keyName, idx) {
				//console.log(obj,"["+keyName+"]", idx, ar);
				obj[keyName] = ar[idx];
				return obj;
			}, {});
		};

		var types = Object.keys(KEYS);
		return types.reduce(function(parsers, type) {
			parsers[type] = parse.bind(KEYS[type]);
			return parsers;
		}, {});

	})();

	//callé une fois que les 2000 points sont loadés (data)
	var parsePoints = function(data) {
		//transforme les points en objets et calcule leur distance par rapport à la localisation
		var points = data.map(function(point) {
			point = Parser.point(point);
			return point;
		});

		var parseAdvantages = function(advantages){
			advantages = advantages.replace(/<br\/>-/g, "</li><li>");
			advantages = advantages.replace(/^-/, "<li>");
			advantages += "</li>";
			return advantages;
		};

		///callback qui sera appelé au clic sur le marker (et sera exécuté dans le contexte du marker défini ci-bas), et doit a son tour appeler le callback donné avec en argument le markup.
		var getInfoWindowMarkup = function(callback) {
			var that = this;
			var title = (ns.lang == "en") ? "Healthcare Professionals": "Professionnels de la santé" ;
			$.ajax({
				url: POINT_UNIQUE_URL + encodeURIComponent("?proID=" + this.id + "&langId=" +LANGS.fr),
				dataType: 'json'
			}).then(function(res) {
				var icon = that.icon;
				var type = icon.split(".")[0].split("/");
				type = type[type.length-1];
				// console.log("res", res, res);
				var details = Parser.details(res[0]);
				//HTML du info window généré icitte
				callback(
					'<div class="customInfoWindow">' +
						'<div class="left">' +
							'<img src="'+icon+'">' +
						'</div>' +
						'<div class="right">' +
							'<h1>' + title + '</h1>' +
							'<p class="type">' + NETWORKS[ns.lang][type] + '</p>' +
							'<hr/>' +
							'<h2 class="caps">'+details.clinic+'</h2>' +
							'<p class="name">'+details.title+' '+details.firstname + ' ' + details.lastname +'</p>' +
							'<p class="address">' + details.addr1 + '</p>'+
							'<p class="address">' + details.city + ', ' + details.prov + ', ' + details.postalcode +'</p>' +
							'<p class="phone">' + details.phone +'</p>' +
							'<h2>Advantage</h2>' +
							'<ul>'+parseAdvantages(details.benefit)+'</ul>' +
						'</div>' +
					'</div>'
				);
			},
			function(a, b, c) {
				console.error(b);
			});
		};

		var w = $(window).width();
		var markers = points.map(function(point) {
			return {
				id: point.id,
				icon: "img/wn/" + point.type.toLowerCase() + ".png",
				location: {
					lat: point.lat,
					lng: point.lng
				},
				getInfoWindowMarkup: (w > 568) ? getInfoWindowMarkup : function(){} //sera appelé avec cet objet comme "this"
			};
		});

		var zoom = 4;
		if (isGeolocated) {
			zoom = 14;
		} else if (w <= 568) {
			zoom = 3;
		}

		map = $('#wn').whatsnearby({
			lat: DEFAULT_LOCATION.lat,
			lng: DEFAULT_LOCATION.lng,
			width: "100%",
			height: 870,
			backgroundColor: '#dedede',
			zoom: zoom,
			placeMainMarker: false,
			mainMarkerIcon: "img/wn/mainmarker-"+ns.lang+".png",
			disableDefaultUI: (w < 1024),
			scrollwheel: false,
			draggable: (w > 1024),
			markers: markers,
			markercluster: true,
			markerclusterStyles: [{
				url: 'img/wn/blank.png',
				width: 49,
				height: 50,
				anchor: [0, 0],
				textColor: '#ffffff',
				textSize: 18
			  }],
			markerclusterOptions: { maxZoom: 13 },
			style: MAP_STYLE,
			centerOffsetX: (w <= 568) ? 0: -$(window).width() / 4,
			centerOffsetY: (w <= 568) ? -868 / 4 : -60,
		}).data('whatsnearby');

		var onLocationSuccess = function(position){
			isGeolocated = true;
			$.getJSON('http://ws.geonames.org/countryCode', {
				lat: position.coords.latitude,
				lng: position.coords.longitude,
				username: 'lagrange',
				type: 'JSON'
			}, function(result){
				if(result.countryCode == "CA") {
					map.moveMainMarker(position.coords.latitude, position.coords.longitude, true, 14);
				} else {
					//not in canada so we leave the default location
				}
			});
		};

		if ("geolocation" in navigator) {
			navigator.geolocation.getCurrentPosition(onLocationSuccess, function(){});
		}
	};

	//load la liste des points
	var loadPromise = $.ajax({
		url: POINTS_URL,
		dataType: 'json'
	});

	//quand points lodaés et position obtenue, parse les points
	$.when(loadPromise).then(
		function(resLoad) {
			parsePoints(resLoad);
		}
	);
});
