"format es6";
"use strict";


import ns from 'ns';
import $ from 'jquery';
import gsap from 'greensock';

import MarkerCluster from '../vendor/markerclusterer.js';
import WhatsNearby from '../vendor/WhatsNearby.js';
import Selectric from '../vendor/jquery.selectric.js';
import MapStyle from './MapStyle.json';

var NETWORKS_ID = {
	"1": "dental",
	"2": "vision",
	"3": "chiropractic",
	"6": "massage therapy",
	"7": "physiotherapy",
	"10": "pharmacy"
};

var NETWORKS = {
	"fr": {
		"chiropractic":"Chiropraticien",
		"dental":"Dentiste",
		"health":"Santé",
		"massage therapy":"Massothérapeute",
		"pharmacy":"Pharmacien",
		"physiotherapy":"Physiothérapeute",
		"travel":"Voyage",
		"vision":"Opticien"
	},
	"en": {
		"chiropractic":"Chiropractic",
		"dental":"Dental",
		"health":"Health",
		"massage therapy":"Massage Therapy",
		"pharmacy":"Pharmacy",
		"physiotherapy":"Physiotherapy",
		"travel":"Travel",
		"vision":"Vision"
	}
};

$.Autocomplete.prototype.forceSuggest = function() {
	var that = this;
	that.currentValue=' ';
	that.getSuggestions(' ');
};

var init = function() {
	var cleanupJSON = function(json){
		var newJSON = [];
		$.each(json, function(index, value){
			newJSON.push(value);
		});
		return newJSON;
	};

	var onJSONLoaded = function(data, type){
		data = cleanupJSON(data);
		var selector = (type == 'assoc') ? '.asso-chooser.claim input, .asso-chooser.home input' : '.asso-chooser.network input';
		$(selector).each(function(i, el){
			const isPrefooter = $(el).closest('.prefooter').length == 1;
			$(el).autocomplete({
				lookup:data,
				maxHeight:200,
				beforeRender: function(ctn){
					if(isPrefooter) {
						$(ctn).addClass('prefooter-suggestions');
					}
				},
				lookupFilter: function(suggestion, query, queryLowerCase){
					return stripaccents(suggestion.value.toLowerCase()).indexOf(stripaccents(queryLowerCase)) !== -1;
				},
				onSelect:onSelectAssociation
			})
		});
		$(".asso-chooser .asso-list").on('click', function(e){
			e.preventDefault();
			var el = $(this);
			var input = el.siblings('input');
			if(input.not(':focus')){
				input.focus();
			}
			setTimeout(function(){
				input.autocomplete('forceSuggest');
			}, 100);
		});
	};

	var protocol = window.location.href.split("/")[0];

	if (location.href.indexOf('sites.static') >= 0 || location.href.indexOf('clients.enclos')) {
		protocol = "proxy.php?url=" + protocol;
	}

	var urlAssoc = (ns.lang === "fr") ? "//santeetudiante.com/json/assoc.asp" : "//ihaveaplan.ca/json/assoc.asp";
	var associationsCampusDef = $.ajax({
		url: protocol + urlAssoc,
		dataType: 'json'
	}).done(function(data) {
		onJSONLoaded(data, "assoc");
	});

	var urlCampus = (ns.lang === "fr") ? "//santeetudiante.com/json/assoccampus.asp" : "//ihaveaplan.ca/json/assoccampus.asp";
	var associationsDef = $.ajax({
		url: protocol + urlCampus,
		dataType: 'json'
	}).done(function(data) {
		onJSONLoaded(data, "assoccampus");
	});

	var setupNetworkChooser = function (chooser, suggestion) {
		var choice = chooser.find('.asso-choice');
		var school = choice.find('.school');
		var assoList = chooser.find('.asso-list');
		school.text(suggestion.value);

		chooser.find('.pin, .asso-name').slideUp(300);
		assoList.slideUp(300);
		choice.delay(400).slideDown(300);

		chooser.find(".change-school").off('click.ihp').on('click.ihp', function(){
			chooser.find('.pin, .asso-name').val("").delay(400).slideDown(300);
			assoList.delay(400).slideDown(300);
			choice.slideUp(300);
		});

		var select = chooser.find("select");
		select.empty();
		var selectString = (ns.lang === "fr") ? "Choisir un réseau" : "Select a Network";
		select.append("<option disabled selected>"+selectString+"</option>");

		var networksLength = suggestion.networks.length;
		var networkId, networkString;
		for (var i = 0; i < networksLength; i++) {
			networkId = NETWORKS_ID[suggestion.networks[i].toString()];
			networkString = NETWORKS[ns.lang][networkId];
			select.append("<option value='"+suggestion.networks[i]+"'>"+networkString+"</option>");
		}
		console.log(select);
		select.selectric('destroy');
		select.selectric({
			onChange:function(e,f,g){
				$(e).parent().parent().addClass("activated");
				var urlRedirect = [
					suggestion.url,
					"?serviceModule=searchResult",
					"&searchMode=AroundCampus",
					"&viewMode=Ls",
					"&CampusId="+suggestion.campusId,
					"&HCNNetworkCategoryId="+$(e).val(),
					"&HCNSearchNearByWithinKilometer=15"
				];
				window.location.href=urlRedirect.join("");
			},
			disableOnMobile:false
		});
	};

	var onSelectAssociation = function(suggestion) {
		var chooser = $(this).parent();

		if(chooser.hasClass('network')) {
			setupNetworkChooser(chooser, suggestion);
		} else if (chooser.hasClass('claim')) {
			window.location.href=suggestion.url_claim;
		} else {
			window.location.href=suggestion.url_home;
		}
	};
};

var stripaccents = function(s){
	var r=s.toLowerCase();
	r = r.replace(new RegExp("\\s", 'g'),"");
	r = r.replace(new RegExp("[àáâãäå]", 'g'),"a");
	r = r.replace(new RegExp("æ", 'g'),"ae");
	r = r.replace(new RegExp("ç", 'g'),"c");
	r = r.replace(new RegExp("[èéêë]", 'g'),"e");
	r = r.replace(new RegExp("[ìíîï]", 'g'),"i");
	r = r.replace(new RegExp("ñ", 'g'),"n");
	r = r.replace(new RegExp("[òóôõö]", 'g'),"o");
	r = r.replace(new RegExp("œ", 'g'),"oe");
	r = r.replace(new RegExp("[ùúûü]", 'g'),"u");
	r = r.replace(new RegExp("[ýÿ]", 'g'),"y");
	r = r.replace(new RegExp("\\W", 'g'),"");
	return r;
};

ns.docReady.then(function(){
	init();
});
