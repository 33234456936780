"format es6";
"use strict";


import ns from 'ns';
import $ from 'jquery';
import gsap from 'greensock';


import Autocomplete from '../vendor/jquery.autocomplete.js';
import Map from './Map.js';
import AssoChooser from './AssoChooser.js';

ns.docReady.then(function(){
	var DAYS = ["sun","mon","tue","wed","thu","fri","sat"];
	var SLIDER_URL = 'json/slider.json';

	var LANGS = {
		fr: 2,
		en: 1
	};


	$('header .arrow-down').on('click', function(){
		$.scrollTo('#wn', 600, { axis:'y' });
	});

	var header = $('header');
	var tabBtns = header.find('.tab-btns');
	var logoDesktop = header.find('.logo');
	var onWindowResize = function() {
		var w = $(window).width();
		if (w > 1024) {
			var h = ((((w / 2) / 3) * 2) / 7);
			tabBtns.css("height", h).css("line-height", h+"px");
			logoDesktop.find('img').width();
			var imgH = h * 0.8;
			logoDesktop.find('img').height(imgH);
		} else {
			tabBtns.css("height", "").css("line-height", "");
			logoDesktop.find('img').height("");
		}

		var images =  header.find('.tab img')
		images.each(function(i, el){
			el = $(el);
			if (w > 1023) {
				el.attr("src", el.attr("src").replace(".mobile.jpg", ".jpg"));
			} else if ( el.attr("src").indexOf('.mobile.jpg') == -1 ) {
				el.attr("src", el.attr("src").replace(".jpg", ".mobile.jpg"));
			}
		});/**/

		var headerHeight = header.height();
		var IMG_W = (w > 1023) ? 1920 : 768;
		var IMG_H = (w > 1023) ? 950 : 768;
		var scaleW = w / IMG_W;
		var scaleH = headerHeight / IMG_H;
		var scale = scaleW > scaleH ? scaleW : scaleH;
		var leftOffset = (w > 1023) ? -(scale * IMG_W) / 2 : - (w / 2);
		header.find('.tab img')
			.width(scale * IMG_W)
			.height(scale * IMG_H)
			.css({ marginLeft:leftOffset, marginTop:-(scale * IMG_H) / 2 });
	};

	$(window).on('resize', onWindowResize);
	$(window).trigger('resize');
});
